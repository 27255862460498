export function getCookieSecret() {
	let name = 'accessToken=';
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(';');
	let response;
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			response = c.substring(name.length, c.length);
			if (response) {
				return response;
			}
		}
	}
}