import {
    fetchApi,
    getTescaseManagementApi,
    getReportManagementApi,
} from '../utils/fetch-api';

import {authApi} from '../utils/authapi'
import { getCookieSecret } from '../constants/CookieExtract'
import jwt_decode from "jwt-decode";
import { method } from 'lodash';

export function login(param) {
	return authApi(`/auth/login`, {
        method: 'POST',
        // body:{
        //     userName,
        //     password
        // }
        body:{
            "email":param.userName.toLowerCase(),
            "secret":param.password
        }
    });
}

export function signup(details) {
    debugger;
	return authApi(`/signup/cognito`, {
        method: 'POST',
        body:details
    });
}

// export function _signup(details) {    
// 	return fetchApi(`http://65.2.23.32:8787/api/signup/cognito`, {
//         method: 'POST',
//         body:details
//     });
// }

export function resendEmail(userName) {
	return authApi(`/auth/resend`, {
        method: 'POST',
        body:{
            "email":userName
        }
    });
}

export function createcode(userName, password) {
    return authApi(`/auth/reset_password`, {
        method: 'POST',
        body:{
            // userName,
            // password
            "email":userName
        }
    });
}

export function resetpassword(userName, password,confirmCode) {
    return authApi(`/auth/confirm_password`, {
        method: 'POST',
        // body:{
        //     userName,
        //     password,
        //     confirmCode
        // }
        body:{
            "email":userName,
            "password":password,
            "verification_code":confirmCode
        }        
    });
}

export function signupComplete(details) {
	return authApi(`/api/signup/cognito`, {
        method: 'PATCH',
        headers: { 
			'Cookie': '_ga=GA1.1.924631854.1644574619; _gid=GA1.1.665798991.1651565894; mp_554cd4b7d1891b6d43ff3853b39f615c_mixpanel=%7B%22distinct_id%22%3A%20%22gvinoth76%2B450%40gmail.com%22%2C%22%24device_id%22%3A%20%2217ee84931d86df-0e55cbc7002cda-576153e-144000-17ee84931d910ee%22%2C%22%24initial_referrer%22%3A%20%22%24direct%22%2C%22%24initial_referring_domain%22%3A%20%22%24direct%22%2C%22%24user_id%22%3A%20%22gvinoth76%2B471%40gmail.com%22%7D'
		},
        body:details
    });
}

export function getCompanyDetails(userId) {    
	return fetchApi(`/getCompany/${userId}`, {
        method: 'get'
    });
}

export function uploadFiles(formData){
    return getTescaseManagementApi(`/api/attachment/uploadfiles`,{
        method:"POST",
        body:formData
    })
}

export function saveCompanyDetails(data,method){
    return fetchApi(`/company`,{
        method:method,
        body:data
    })
}

export function fetchCompanyUser(id){
    return fetchApi(`/getCompany/${id}`,{
        method:"GET"     
    })
}

//Hitting another service

 export function updateUser(payload){
    return fetchApi(`/updateUser`,{
        method:'PUT',
        body:payload
    })
 }

 export async function getClientProfile(userId) {
    return await getReportManagementApi(`/api/v1/client/profileview/${userId}`, {
        method: 'get'
    });
}

export async function clientProfileUpdate(formData) {
    return await getReportManagementApi(`/api/v1/client/createclientprofile`, {
        method: 'POST',
        body: formData
    });
}

export function getUserDetails(details) {
    return fetchApi(`/getUserDetails/${details.userEmail}`, {
        method: "GET"
    })
}

 export function updatePassword(payload){
    return fetchApi(`/updatePassword`,{
        method:'POST',
        body:payload
    })
 }

export function tokenExpiry() {
    //let token = JSON.parse(localStorage.getItem('USER_INFO'));
    var token = getCookieSecret();
    
    if(token){
        let decodedToken = jwt_decode(token);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            return true
        } else {
            return false;
        }
    }else{
        return true;
    }
}

export function getUserProducts() {
    return fetchApi(`/user/subscription`, {
        method: 'get'
    });
}

export function getCaptachaResponse(token) {

    return fetchApi(`/signup/getCaptachaResponse/${token}`, {
        method: 'get'
    });

}