import * as types from './types';
import configureStore from '../../../store/configureStore';

export function login(userName, password) {    
	return { type: types.LOGIN_REQUEST, payload: { userName, password } };
}
export function loginOP(payload) {    
    return { type: types.LOGIN_OP_REQUEST, payload: payload };
}
export const logoutUser = () => { 
    const store = configureStore(); 
    localStorage.removeItem('USER_INFO'); 
    store.persistor.purge();
    return { type: types.LOGOUT_SUCCESS };
};

export function loginReset(payload) {    
    
    return { type: types.LOGIN_RESET_REQUEST, payload: payload };
}

export function loginUpdate(result) {    
	return { type: types.LOGIN_UPDATE_REQUEST, result };
}

export const logout = () => {
  
    const payload = {
        userInfo: {
            isLoggedIn: false,
            firstname: '',
            lastname:'',
            email:'',
            username:'',
            allInfo:{}
        },
        error: [],
        isLoggedIn: false,
        isError: false,
        isPending: false
    }

    return { type: types.LOGOUT_REQUEST, payload };
};

export function activeMenu(payload) {
    return { type: types.MENU_ACTIVE_REQUEST, payload: payload };
}