import axios from 'axios';

export function fetchApi(url, payload) {
  //https://dtlehta1thuw9.cloudfront.net/auth/api/auth

  return axios({
    method: payload.method,
    url: process.env.REACT_APP_AUTH_URL + url,
    data: payload.body ? payload.body : null,
    params: payload.params ? payload.params : null

  }).then((data) => (data))
    .catch((Error) => {
      return { ...Error }
    }
  );
}

export function getTescaseManagementApi(url, payload) { 
  //https://dtlehta1thuw9.cloudfront.net/testcasemgmt

  return axios({
    method: payload.method,
    url: process.env.REACT_APP_TESTCASEMANAGEMENT + url,
    data: payload.body ? payload.body : null,
    params: payload.params ? payload.params : null

  }).then((data) => {
    return data;
  })
    .catch((Error) => ({ Error }));
}

export async function getReportManagementApi(url, payload) { 
  //https://dtlehta1thuw9.cloudfront.net/quantifyreport 

  return await axios({
    method: payload.method,
    url: process.env.REACT_APP_REPORT_URL + url,
    data: payload.body ? payload.body : null,
    params: payload.params ? payload.params : null

  }).then((data) => (data))
    .catch((Error) => ({ Error }));
}

export function getSubscribeApi(url, payload) { 
  return axios({
    method: payload.method,
    url: process.env.REACT_APP_SUBSCRIPTION + url,
    data: payload.body ? payload.body : null,
    params: payload.params ? payload.params : null,
  }).then((data) => {
    return data;
  })
    .catch((Error) => ({ Error }));
}


export function getProductSubscribeApi(url, payload) {
  return axios({
    method: payload.method,
    url: process.env.REACT_APP_PRODUCTSUBSCRIPTION + url,
    data: payload.body ? payload.body : null,
    params: payload.params ? payload.params : null,
  }).then((data) => {
    return data;
  })
    .catch((Error) => ({ Error }));
}

export function getplatformadminApi(url, payload) {
  return axios({
    method: payload.method,
    url: process.env.REACT_APP_PLATFORMADMIN + url,
    data: payload.body ? payload.body : null,
    params: payload.params ? payload.params : null
  }).then((data) => (data))
    .catch((Error) => ({ Error }));
}

export function getSubscribeApiV2(url, payload) {
  //https://dtlehta1thuw9.cloudfront.net/auth/api/subscription

  return axios({
    method: payload.method,
    url: process.env.REACT_APP_SUBSCRIPTION_V2 + url,
    data: payload.body ? payload.body : null,
    params: payload.params ? payload.params : null,
  }).then((data) => {
    return data;
  })
    .catch((Error) => ({ Error }));
}
