import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import * as api from '../../../endpoints/gateway';
import * as types from './types';
// import { push } from 'react-router-redux';
import configureStore from '../../../store/configureStore';
import jwt_decode from "jwt-decode";

function* login({ payload }) {
    try {        
        yield put({ type: types.LOGIN_LODING });
        let result;
        result = yield call(api.login, payload.userName, payload.password);         
        if(result.status == 200) {    
           
                const userInfo = {                
                    isLoggedIn: true,
                    firstname: result.data.details.login_name,
                    lastname:result.data.lastName,
                    email:result.data.details.userEmail,
                    username:result.data.userName,
                    userId:result.data.userId,
                    allInfo:result.data
                };
                localStorage.setItem('USER_INFO', JSON.stringify(userInfo));            
                yield put({ type: types.LOGIN_SUCCESS, payload: {userInfo} });
         

        } else if(result.response.status == 500){        
              
            yield put({ type: types.LOGIN_FAILURE, payload: result.response.data.message }); 

        }else {
            console.log(result);
            console.log(result.response.data.errors[0].message);
            const usererror = {
                dseverity: 'error',
                dAlertTitle: 'Error',
                error: result.response.data.errors[0].message,
                isError: true
            }
    
            yield put({ type: types.LOGIN_FAILURE, payload: result.response.data.errors[0].message });
        }
        
    } catch (err) {
        //yield put({ type: types.LOGIN_FAILURE, payload: err });
        // console.log('c err', err);

    }
}

function* logoutUser({ payload }) {
    try {  
             
        localStorage.removeItem('USER_INFO'); 
        const store = configureStore();
        store.persistor.purge();
        yield put({ type: types.LOGOUT_SUCCESS, payload });
        return { type: types.LOGOUT_SUCCESS };
    } catch (err) {
        yield put({ type: types.LOGOUT_FAILURE, payload: "error" });
    }
}

function* loginReset({ payload }) {
  
    try {  
        yield put({ type: types.LOGIN_RESET_SUCCESS, payload });
    } catch (err) {
        yield put({ type: types.LOGIN_RESET_FAILURE, payload: "error" });
    }
}

function* loginUpdate({ result }) {
    try { 
        debugger;
        if(result.status == 200) {  

            let decodedToken = jwt_decode(result.data.details.secret);
            
            var allInfo = {
                firstname: result.data.details.login_name.split(' ')[0],
                lastname: result.data.details.login_name.split(' ')[1],
                email: decodedToken?.email,
                username: decodedToken?.email,
                userId: decodedToken?.id,
            }
            const userInfo = {                
                isLoggedIn: true,
                firstname: result.data.details.login_name.split(' ')[0],
                lastname: result.data.details.login_name.split(' ')[1],
                email: decodedToken?.email,
                username: decodedToken?.email,
                userId: decodedToken?.id,
                allInfo
            };

            document.cookie = 'accessToken=' + result.data.details.secret + ";";

            localStorage.setItem('USER_INFO', JSON.stringify(userInfo)); 
            yield put({ type: types.LOGIN_SUCCESS, payload: {userInfo} });

        } else if(result.response.status == 500){        
            localStorage.clear();
            yield put({ type: types.LOGIN_FAILURE, payload: result.response.data.message }); 

        }else {
          
            const usererror = {
                dseverity: 'error',
                dAlertTitle: 'Error',
                error: result.data.error.message,
                isError: true
            }
            localStorage.clear();
            yield put({ type: types.LOGIN_FAILURE, payload: result.data.error });
        }
    } catch (err) {
        const usererror = {
            dseverity: 'error',
            dAlertTitle: 'Error',
            error: "",
            isError: true
        }
        localStorage.clear();
        yield put({ type: types.LOGIN_FAILURE, payload: "Incorrect email or password" });
    }
}

function* loginOP({ payload }) {
    try {
    } catch (err) {
        
    }
}
function* activeMenu({ payload }) {
    try {
        yield put({ type: types.MENU_ACTIVE_SUCCESS, payload });
    } catch (err) {
        yield put({ type: types.MENU_ACTIVE_FAILURE, payload: "error" });
    }
}

function* loginSaga(data) {    
    yield takeLatest(types.LOGIN_REQUEST, login);
    yield takeLatest(types.LOGOUT_REQUEST, logoutUser);
    yield takeLatest(types.LOGIN_OP_REQUEST, loginOP);
    yield takeLatest(types.LOGIN_RESET_REQUEST, loginReset);
    yield takeLatest(types.LOGIN_UPDATE_REQUEST, loginUpdate);
    yield takeLatest(types.MENU_ACTIVE_REQUEST, activeMenu);
}

export default loginSaga;
